import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading } from '../../../ui/Elements'
import { ContactButton, OffersBox } from '../../../ui/CustomElements'
import { ArrowRight } from '../../../ui/AllImages'
import { useTranslation } from 'react-i18next'
import WhyChoose from '../whyChoose/WhyChoose'

const Offers = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966539628781';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <OffersWrapper className='container-fluid common-space servicesFluid' id='offers'>
            <Container>
                <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                    <MainHeading className='py-2 main-text'>{t('Irresistibleoffers')}</MainHeading>
                </Box>
                <Row className='offersRow'>
                    
                    <Col lg={6}>
                        <Row className='planRow'>
                            <Col lg={6} className='planCol planCol1'>
                                <OffersBox className="planLondon" city={t('London')} desc={t('6daysLondon')} />
                                <OffersBox className="planMalaysia" city={t('Malaysia')} desc={t('6daysMalaysia')} />
                                <OffersBox className="planItaly" city={t('Italy')} desc={t('6daysItaly')} />
                            </Col>
                            <Col lg={6} className='planCol planCol2'>
                                <OffersBox className="planDubai" city={t('Dubai')} desc={t('6daysDubai')} />
                                <OffersBox className="planMaldives" city={t('Maldives')} desc={t('6daysMaldives')} />
                                <OffersBox className="planFrance" city={t('France')} desc={t('6daysFrance')} />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className='pb-3'>
                        <Box className='offerBanner offerBanner1'>
                            <Box className='offerContentContainer'>
                                <Box className="offerHeading">{t('Discoverwinter')}</Box>
                                <Box className="offerText">{t('Bookdream')}</Box>
                                <ContactButton icon={ArrowRight} label={t('Clickhereinformation')} onClick={WhatsApp} />
                            </Box>
                        </Box>
                        <Box className='offerBanner offerBanner2'>
                            <Box className='offerContentContainer'>
                                <Box className="offerHeading">{t('Yourdreamvacation')}</Box>
                                <Box className="offerText">{t('Dontmissoffer')}</Box>
                                <ContactButton icon={ArrowRight} label={t('Clickhereinformation')} onClick={WhatsApp} />
                            </Box>
                        </Box>
                    </Col>
                </Row>
                <WhyChoose />
            </Container>
        </OffersWrapper>

    )
}
const OffersWrapper = styled.section`
    
`

export default Offers
