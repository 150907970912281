import styled from 'styled-components'
import { Box, ButtonStyled, SpanStyled } from './Elements';
import { useTranslation } from 'react-i18next'

export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}


export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='18px' />
                )}
                <SpanStyled color='var(--white)' size='15px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}
export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <SpanStyled color='var(--white)' size='16px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='18px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const OffersBox = (props) => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966539628781';
        //const message = 'Hello, this is Al-barrak Trading';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    const unwantedProps = ['city', 'desc', 'className'];
    return (
        <OffersBoxContainer className={`planWrapper ${props.className || ''}`} {...sliceProps(props, unwantedProps)}>
            <Box className='planContent'>
                <Box className='planCity'>{props.city}</Box>
                <Box className='planDays'>{props.desc}</Box>
                <ContactButton label={t('OfferDetails')} padding='5px 15px' onClick={WhatsApp} />
            </Box>
        </OffersBoxContainer>
    )
}

export const ServicesBox = (props) => {
    const unwantedProps = ['desc', 'className'];
    return (
        <ServicesBoxContainer className={`servicesWrapper ${props.className || ''}`} {...sliceProps(props, unwantedProps)}>
            <Box className='servicesContent'>
                <Box className='servicesText'>{props.desc}</Box>
            </Box>
        </ServicesBoxContainer>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label" ];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute' />
            )}
        </InputStyledWrap>
    )
}
export const InputStyledWrap = styled.div`
    box-shadow: 3px 3px 10px #e3e3e3e0;
    -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 25px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`
export const InputStyled = styled.input`
    border: none;
    // box-shadow: 3px 3px 10px #e3e3e394;
    border-radius: 25px;
    height: 43px;
    color: var(--darkGrey) !important;
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 13px;
        color: #a9a9a9 !important;
    }
`
export const OffersBoxContainer = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom 0 left 0;
    padding: 22px 10px 22px 10px;
    border-radius: 17px;
    overflow: hidden;
    align-items: flex-end;
    margin: 15px 0 15px 0;
    float: left;
    .planContent{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        color: var(--white);
        flex-wrap: wrap;
        .planCity{
            width: 100%;
            font-family: var(--semiBold);
            font-size: 22px;
            line-height: 26px;
        }
        .planDays{
            width: 100%;
            font-family: var(--regular);
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;
        }
        
    }
`

export const ServicesBoxContainer = styled.div`
    display: flex;
    width: 100%;
    height: 230px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom 0 left 0;
    padding: 22px 10px 22px 10px;
    border-radius: 17px;
    overflow: hidden;
    align-items: flex-end;
    margin: 12px 0 12px 0;
    float: left;
    position: relative;
    &::after{
        content: '';
        width: 100%;
        bottom: 0px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.73) 0%, rgba(0,0,0,0) 100%);
        position: absolute;
        z-index: 1;
        height: 110px;
        left: 0;
        right: 0;
    }
    .servicesContent{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        color: var(--white);
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        .servicesText{
            width: 100%;
            font-family: var(--medium);
            font-size: 16px;
            line-height: 18px;
        }
        
    }
`

export const ContactWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background:${props => props.bg ? props.bg : "var(--gradientBlue)"};
    color: ${props => props.color ? props.color : "var(--white)"};
    padding: ${props => props.padding ? props.padding : "10px 20px"};
    border: ${props => props.border ? props.border : "none"};
    outline: ${props => props.border ? props.border : "none"};
    border-radius: ${props => props.radius ? props.radius : "20px"};
    box-shadow: ${props => props.shadow ? props.shadow : ""};
    line-height: 20px;
    gap: 8px;
    margin:0 0px;
    transition: all 0.5s ease;
    svg{
        [stroke]{
            stroke: ${props => props.stroke ? props.stroke : "var(--white)"} ;
        }
        [fill]{
            fill: ${props => props.fill ? props.fill : "var(--white)"};
        }
    }
    &:hover{
        background:transparent;
        outline:1px solid var(--themeColor);
        color:var(--themeColor);
        svg{
            [stroke]{
                stroke: ${props => props.stroke ? props.stroke : "var(--themeColor)"} ;
            }
            [fill]{
                fill: ${props => props.fill ? props.fill : "var(--themeColor)"};
            }
        }
        span{
            color:var(--themeColor);
        }
    }

  }
`
export const ButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background-color:${props => props.bg ? props.bg : "transparent"};
    color: ${props => props.color ? props.color : "var(--themeColor)"};
    padding: ${props => props.padding ? props.padding : "6px 16px"};
    border: ${props => props.border ? props.border : "1px solid var(--themeColor)"};
    border-radius: ${props => props.radius ? props.radius : "20px"};
    background: transparent;
    gap: 8px;
    transition: all 0.5s ease;
    &:hover{
        background-color: var(--themeColor);
        border: 1px solid var(--themeColor);
        color: var(--white);
        svg{
            [stroke]{
                stroke: var(--white);
            }
            [fill]{
                fill: var(--white);
            }
        }
    }
    
}
`